<template>
	<div>
        <qrcode-vue :value="value"></qrcode-vue>
	</div>
</template>
<script>
    import QrcodeVue from 'qrcode.vue';
	export default {
		name: 'QR',
		components: {
            QrcodeVue
        },
		data () {
	        return {
                value: 'http://localhost:8080/port/3'
            };
	    },
	    methods: {},
	    computed: {},
		mounted () {}
	};
</script>
<style scoped lang="scss">
    @import "@/assets/scss/_variables.scss";
</style>
